<template>
  <div class='users-page'>
    <div class='actions d-flex justify-end align-center mb-20'>
<!--      <a-input class='mr-15 w-200' placeholder='Email...' v-model='query.email' @change='onChangeFilter' />-->
      <a-input class='mr-15 w-200' placeholder='Wallet address...' v-model='query.walletAddress' @change='onChangeFilter' />
      <a-button @click="onRefreshData" class="mr-15">
        <a-icon type='sync' />
        Refresh
      </a-button>
    </div>
    <a-table :columns='columns' :data-source='listUsers' :loading='loading' rowKey="stt" :scroll='{ x: 1200 }'
             :pagination="{
      current: query.page,
      pageSize: query.size,
      total: total
             }" @change="onChangeTable">
      <template slot='walletAddress' slot-scope='item'>
        <a-icon type='copy' class='mr-10 ic-copy' @click='copyURL(item.walletAddress)' />
        <span>{{item.walletAddress}}</span>
      </template>
      <template slot='type' slot-scope='item'>
        <a-tag color="red" v-if='item.type === 0'>DOWN</a-tag>
        <a-tag color="green" v-if='item.type === 1'>UP</a-tag>
      </template>
      <template slot='amount' slot-scope='item'>
        {{item ? item.toFixed(2) : ''}}
      </template>
      <template slot='time' slot-scope='item'>
        {{item.time ? convertGmt9(item.time, 'DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss') : ''}}
      </template>
<!--      <template slot='actions' slot-scope='item'>-->
<!--        <a-tooltip v-if='item.status === 1'>-->
<!--          <template slot="title">-->
<!--            Inactive user-->
<!--          </template>-->
<!--          <a-button type='link' size='small' @click='onChangeStatus(item)'>-->
<!--            <a-icon type="close-circle" theme='twoTone' two-tone-color="#ff5500" />-->
<!--          </a-button>-->
<!--        </a-tooltip>-->
<!--        <a-tooltip v-if='item.status === 0'>-->
<!--          <template slot="title">-->
<!--            Active user-->
<!--          </template>-->
<!--          <a-button type='link' size='small' @click='onChangeStatus(item)'>-->
<!--            <a-icon type="check-circle" theme='twoTone' two-tone-color="#52c41a" />-->
<!--          </a-button>-->
<!--        </a-tooltip>-->
<!--      </template>-->
    </a-table>
  </div>
</template>

<script>
import UsersService from "@/services/app/users.service";
import {copyURL} from "@/helpers/common";
import {convertGmt7, convertGmt9} from "../../helpers/time";

export default {
  name: 'Users',
  components: {
    UsersTable: () => import('@/components/Pages/Users/UsersTable')
  },
  data() {
    return {
      breadcrumb: [
        {
          path: '/account',
          label: 'List users'
        }
      ],
      query: {
        page: 1,
        size: 10,
      },
      timeout: null,
      listUsers: [],
      total: 0,
      loading: false,
      columns: [
        {
          title: 'Order',
          width: 80,
          key: 'stt',
          dataIndex: 'stt',
        },
        {
          title: 'Wallet address',
          scopedSlots: { customRender: 'walletAddress' },
          ellipsis: true,
          key: 'walletAddress',
        },
        {
          title: 'Type',
          scopedSlots: { customRender: 'type' },
          width: 180,
          key: 'type',
        },
        {
          title: 'Amount',
          scopedSlots: { customRender: 'amount' },
          width: 140,
          dataIndex: 'amount',
          key: 'amount',
        },
        {
          title: 'Time',
          width: 200,
          scopedSlots: { customRender: 'time' },
          key: 'time',
        },
      ],
    }
  },
  created() {
    this.setBreadcrumb(this.breadcrumb)
  },
  mounted() {
    this.onFetchData()
  },

  methods: {
    convertGmt9,
    convertGmt7,
    copyURL,
    async onFetchData() {
      this.loading = true
      const response = await UsersService.getList(this.query)
      if (response.code === 1) {
        this.listUsers = response.data ? response.data.map((item, index) => ({
          ...item,
          stt: (this.query.page - 1) * this.query.size + index + 1
        })) : []
        this.total = response.totalElement || 0
      }
      this.loading = false
    },
    async onRefreshData() {
      this.query.page = 1
      this.query.walletAddress = ''
      await this.onFetchData()
    },
    onChangeFilter() {
      if (this.timeout) {
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.timeout = setTimeout(() => {
        this.query.page = 1
        this.onFetchData();
      }, 800);
    },
    onChangeTable(val) {
      this.query.page = val.current
      this.onFetchData()
    },
  }
}
</script>

<style scoped>
.users-page {
  padding: 24px;
}
</style>

import {http} from '@/plugins/axios/axios'

const getList = (params) => {
  return http.get('/v1/users', {
    params
  })
}

const verifyUser = () => {
  return http.get('/v1/user/info')
}

const changeStatus = (id, params) => {
  return http.put('/v1/account/active/' + id, {}, {
    params
  })
}

export default {
  getList, changeStatus,
  verifyUser
}
